import logo from './logo.svg';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './App.css';
import Home from  './pages';

function App() {
  return (
    <div className="App">
       <Home />
    </div>
  );
}

export default App;
